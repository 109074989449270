<template>
    <div>
        <div class="login-page">
            <div class="login-form">
                <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
                    <div class="text-center mb-4">
                        <img class="transcrypt-logo-login-and-register mb-5"
                             src="/assets/remittance-assets/img/transcrypt-logo-colour.png"/>
                    </div>
                    <br>
                    <br>
                    <p class="text-center">
                        Thank you for verifying your email !
                    </p>
                    <p class="text-center">
                        Please use this email address when logging to Transcrypt.
                    </p>
                    <p class="text-center">
                        Start sending money to yourself or your loved ones!
                    </p>

                    <br>
                    <br>
                    <br>
                    <button @click="goToLogin()" class="btn btn-success btn-block mb-2" id="login-button">
                        Login
                    </button>

                </div>
            </div>
        </div>

    </div>
</template>
<script>

    export default {
        name: 'Confirm Login',
        data() {
            return {
                // imageSrc: "",
                // emailLogin: "",
                // passwordLogin: "",
                //
                // validationEmailLoginError: false,
                // validationPasswordLoginError: false,
                // validationLoginError: false,
            }
        },
        watch: {
            $route() {

            },

        },
        mounted() {
        },
        metaInfo: {
            title: 'TransCrypt - Confirm Email | Send Money Faster with Cryptocurrency',
        },

        methods: {
            goToLogin() {
                this.$router.push('/account/login');
            }


        }
    }
</script>
